<template>
  <ul class="multilevel-dropdown" :class="useTailwind ? 'pt-1 pl-4 mb-4' : ''">
    <MultilevelDropdownNode
      v-for="option of options"
      :key="option.value"
      :value="option.value"
      :label="option.label"
      :options="option.options"
      :isMulti="isMulti"
    >
    </MultilevelDropdownNode>
  </ul>
  <ul class="frontend-text-input__error" v-if="errors && errors.length > 0">
    <li class="frontend-radio-input__error" v-for="error in errors" :key="error">
      {{ error }}
    </li>
  </ul>
</template>

<script>
import _ from 'lodash';
import MultilevelDropdownNode from './MultilevelDropdownNode.vue';

export default {
  components: {
    MultilevelDropdownNode,
  },
  provide() {
    return {
      mainDropDown: this,
    };
  },
  props: {
    valueLimit: Number,
    applyValueLimit: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      /** array of codes to select */
      type: Array,
      default: () => [],
    },
    isMulti: {
      type: Boolean,
    },
    useTailwind: {
      type: Boolean,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkedOptions: [...this.modelValue],
    };
  },
  watch: {
    modelValue(newValue) {
      this.checkedOptions = [...newValue];
    },
  },
  computed: {
    valueLimitReached() {
      return this.applyValueLimit && this.checkedOptions.length >= this.valueLimit;
    },
  },
  methods: {
    toggle(value) {
      if (this.isChecked(value)) {
        this.uncheck(value);
      } else {
        this.check(value);
      }
    },
    check(value) {
      if (!this.isMulti) {
        this.checkedOptions = [];
      }

      if (this.checkedOptions.includes(value)) {
        return;
      }

      if (this.valueLimitReached) {
        return;
      }

      this.checkedOptions.push(value);

      this.$emit('update:errors', []);
      this.$emit('update:modelValue', [...this.checkedOptions]);
    },
    uncheck(value) {
      _.remove(this.checkedOptions, (element) => element === value);
      this.$emit('update:errors', []);
      this.$emit('update:modelValue', [...this.checkedOptions]);
    },
    isChecked(value) {
      return this.checkedOptions.includes(value);
    },
  },
};
</script>
